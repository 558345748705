// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationItem-container-3XvnK {\n  display: block;\n  margin: 0;\n}\n.NotificationItem-container-3XvnK:not(:last-child) {\n  border-bottom: 1px solid #e0dfde;\n}\n.NotificationItem-container-3XvnK.NotificationItem-unread-1jcLV .NotificationItem-content-35Y-8 {\n  background-color: #e6f3ff;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 {\n  padding: 5px;\n  padding-right: 15px;\n  font-size: 13px;\n  width: 100%;\n  line-height: 1.6;\n  display: block;\n  --fi-size: 14px;\n}\n@media (max-width: 400px) {\n  .NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 {\n    padding-right: 25px;\n  }\n}\n.NotificationItem-si-content-width-max-xs-1KQC1 .NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 {\n  padding-right: 25px;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8,\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 a {\n  color: #1a1a1a;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8:hover,\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 a:hover {\n  text-decoration: none;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 .NotificationItem-avatar-u_gPP {\n  margin-right: 5px;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 .NotificationItem-bold-iy8Jl {\n  font-weight: bold;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8:hover {\n  background-color: #e6f3ff;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8:hover .NotificationItem-unread_toggle-3cW0X {\n  opacity: 1;\n}\n.NotificationItem-container-3XvnK .NotificationItem-content-35Y-8 .cols--hug {\n  display: inline-flex;\n}\n.NotificationItem-meta-3Kkq5 {\n  color: #7a7a79;\n}\n.NotificationItem-unread_toggle-3cW0X {\n  opacity: 0;\n  color: #7a7a79;\n}\n.NotificationItem-unread_toggle-3cW0X:hover {\n  color: black;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "NotificationItem-container-3XvnK",
	"unread": "NotificationItem-unread-1jcLV",
	"content": "NotificationItem-content-35Y-8",
	"si-content-width-max-xs": "NotificationItem-si-content-width-max-xs-1KQC1",
	"avatar": "NotificationItem-avatar-u_gPP",
	"bold": "NotificationItem-bold-iy8Jl",
	"unread_toggle": "NotificationItem-unread_toggle-3cW0X",
	"meta": "NotificationItem-meta-3Kkq5"
};
module.exports = exports;
