export function extractFileName(uri) {
    return decode(getLastPart(uri));
}
export function decode(uri) {
    return window.decodeURI(uri);
}
export function getLastPart(uri) {
    if (!uri) {
        return '';
    }
    return uri.match(/.+\/(.*)$/)[1];
}
export function stripScheme(uri) {
    if (!uri) {
        return '';
    }
    return uri.match('https?://(.*)$')[1];
}
export function split(uri) {
    if (!uri) {
        return '';
    }
    const m = uri.match('(https?://)?(.*?)$');
    const scheme = m[1];
    const rest = m[2];
    const parts = rest.split('/');
    return scheme ? [scheme].concat(parts) : parts;
}
