import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { trackEvent } from '../../../services/Tracking';
import Avatar from '../../Avatar';
import DateDisplayMinimal from '../../DateTime/DateDisplayMinimal';
import ModuleIcon from '../../ModuleIcon';
import ReadUnreadToggler from '../../ReadUnreadToggler';
import * as templateService from '../services/templateService';
import styles from './style.m.less';
export default function NotificationItem({ item, actions: { doMarkAsRead, doMarkAsUnread }, hideReadUnreadButton }) {
    if (!templateService.isSupported(item)) {
        return null;
    }
    const { actor, createdAt, visitedAt, id, module } = item;
    const isRead = !!visitedAt;
    const itemClasses = classNames([styles.container], { [styles.unread]: !isRead });
    const replacedTemplate = templateService.transform(item);
    const callToAction = templateService.getCallToAction(item);
    const visit = event => {
        if (event.isPropagationStopped()) {
            return;
        }
        doMarkAsRead(id, true);
        trackEvent({ category: 'notifications', action: 'click', label: item.type });
        callToAction();
    };
    const onReadToggler = event => {
        event.stopPropagation();
        event.preventDefault();
        if (isRead) {
            doMarkAsUnread(id);
        }
        else {
            doMarkAsRead(id);
        }
    };
    /*
        Temporary hack to handle anonymous users, the currently are null.
        In Activity Stream we send 'isAnonymous: true' from the back end
        We should probably do this too.
    */
    const itemActor = actor || {
        logo: '/images/characters/avatar-anonym-own.svg',
    };
    return (_jsx("li", { className: itemClasses, children: _jsx("a", { role: "link", className: styles.content, onClick: visit, children: _jsxs("div", { className: "grid grid--no_gutters", children: [_jsx("div", { className: classNames('grid-item cols--hug', styles.avatar), children: _jsx(Avatar, { user: itemActor, size: "XL", ariaHidden: true }) }), _jsxs("div", { className: "grid-item", children: [replacedTemplate, _jsxs("div", { className: styles.meta, children: [_jsx(ModuleIcon, { type: module }), " ", _jsx(DateDisplayMinimal, { date: createdAt, withTooltip: false })] })] }), hideReadUnreadButton === true ? null : (_jsx("div", { className: "grid-item cols--hug", children: _jsx(ReadUnreadToggler, { className: styles.unread_toggle, isRead: isRead, onClick: onReadToggler }) }))] }) }) }));
}
