export function isIE10() {
    if (typeof window === 'undefined') {
        return false;
    }
    return /MSIE 10/i.test(window.navigator.userAgent);
}
export function isIE11() {
    if (typeof window === 'undefined') {
        return false;
    }
    return /rv:11.0/i.test(window.navigator.userAgent);
}
// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
export function isChrome() {
    var isChromium = window.chrome, winNav = window.navigator, vendorName = winNav.vendor, isOpera = winNav.userAgent.indexOf('OPR') > -1, isIEedge = winNav.userAgent.indexOf('Edge') > -1, isIOSChrome = winNav.userAgent.match('CriOS');
    if (isIOSChrome) {
        return true;
    }
    else if (isChromium !== null &&
        isChromium !== undefined &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false) {
        return true;
    }
    else {
        return false;
    }
}
export function isTabActive() {
    return document && document.visibilityState;
}
export function addTabActivityListener(cb) {
    const event = 'visibilitychange';
    const listener = () => cb(isTabActive());
    document.addEventListener(event, listener);
    return () => document.removeEventListener(event, listener);
}
