import { connect } from 'react-redux';
import { actionCreators } from '../../Notifications/ducks';
import NotificationAlert from './presenter';
function mapStateToProps() {
    return {};
}
function mapDispatchToProps(dispatch) {
    return {
        onShowMore: () => dispatch(actionCreators.doShowNotificationsDropdown(true)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationAlert);
