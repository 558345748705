import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../ducks';
import NotificationItem from './presenter';
const EMPTY_OBJECT = {};
function mapStateToProps() {
    return EMPTY_OBJECT;
}
function mapDispatchToProps(dispatch) {
    const { doMarkAsRead, doMarkAsUnread } = actionCreators;
    return { actions: bindActionCreators({ doMarkAsRead, doMarkAsUnread }, dispatch) };
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);
