import { jsx as _jsx } from "react/jsx-runtime";
import { noop } from 'lodash';
import * as replaceService from '../../../../features/ActivityStream/services/replaceService';
import { TEMPLATES } from './notification-templates';
// @todo this is basically a copy of ActivityStream/templateService.. can we reuse?
export { findTemplate, getCallToAction, getContentItem, isSupported, transform };
function isSupported({ type }) {
    return !!TEMPLATES[type];
}
function findTemplate(item) {
    const { type } = item;
    if (!isSupported({ type })) {
        return;
    }
    const tpl = TEMPLATES[type];
    if (tpl.chooseTemplate) {
        return tpl.chooseTemplate(item);
    }
    return tpl.standard;
}
function getContentItem(item) {
    const template = findTemplate(item);
    return template.getContentItem ? template.getContentItem(item) : _jsx("noscript", {});
}
function transform(item) {
    const template = findTemplate(item);
    if (!template) {
        return;
    }
    let { text, getValuesFrom } = template;
    try {
        const templateValues = getValuesFrom ? getValuesFrom(item) : {};
        return replaceService.transformSentence(text, templateValues);
    }
    catch (e) {
        return;
    }
}
function getCallToAction(item) {
    const template = findTemplate(item);
    if (!template) {
        return noop;
    }
    const cta = template.getCallToAction || noop;
    return cta(item);
}
