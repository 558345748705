import { filter, reduce } from 'lodash';
export function removeAllAggregatedNotifications(newNotifications, oldNotifications) {
    return reduce(newNotifications, (mem, notification) => {
        return removeAggregatedNotifications(notification, mem);
    }, oldNotifications);
}
export function removeAggregatedNotifications(newNotification, oldNotifications) {
    if (newNotification.aggregatedOverActors) {
        return filter(oldNotifications, oldNotification => {
            return !isAggregatedOverActors(newNotification, oldNotification);
        });
    }
    if (newNotification.aggregatedOverRepetition) {
        return filter(oldNotifications, oldNotification => {
            return !isAggregatedOverRepetition(newNotification, oldNotification);
        });
    }
    return oldNotifications;
}
export function isAggregatedOverActors(newNotification, oldNotification) {
    return hasSameTypeAndEntity(newNotification, oldNotification);
}
export function isAggregatedOverRepetition(newNotification, oldNotification) {
    return hasSameTypeAndActorAndEntity(newNotification, oldNotification);
}
function hasSameTypeAndActorAndEntity(a, b) {
    return hasSameEntity(a, b) && hasSameActor(a, b) && hasSameType(a, b);
}
function hasSameTypeAndEntity(a, b) {
    return hasSameEntity(a, b) && hasSameType(a, b);
}
function hasSameEntity(a, b) {
    return a.entityId === b.entityId;
}
function hasSameActor(a, b) {
    return a.actor.id === b.actor.id;
}
function hasSameType(a, b) {
    return a.type === b.type;
}
