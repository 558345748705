import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { createMountPoint, forEachMountPoint } from '../../helper';
import SafeImage from './SafeImage';
export const name = 'images';
export const propType = PropTypes.bool;
export const defaultProp = false;
const RTE_IMAGE = 'rte-image';
export function onParse(content) {
    const re = /(^|[>;]|\s*)(https?:\/\/[^\s]+?\.(jpg|png|gif))/gm;
    return content.replace(re, (match, ws, src) => ws + createMountPoint(RTE_IMAGE, { src }));
}
export function onMount(node) {
    forEachMountPoint(node, RTE_IMAGE, data => _jsx(SafeImage, Object.assign({}, data)));
}
