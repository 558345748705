import popupAlert from '../../../../../common/models/popup-alert/singleton';
import * as browser from '../../../../services/Browser';
import store from '../../../../store';
import { selectors } from '../../ducks';
import { create } from './factory';
const getCurrentCount = () => selectors.getCount(store.getState());
// this delayed invocation of create is used to break a cirular dependency issue
var _push;
function push() {
    if (!_push) {
        _push = create(getCurrentCount, browser, popupAlert, setTimeout, window.location.pathname).push;
    }
    // eslint-disable-next-line
    return _push.apply(this, arguments);
}
export { push };
