// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".highlight-standard-3Trtp {\n  background-color: #ffcc00;\n  box-shadow: 0 0 2px 2px #ffcc00;\n}\n", ""]);
// Exports
exports.locals = {
	"standard": "highlight-standard-3Trtp"
};
module.exports = exports;
