import { connect } from 'react-redux';
import { selectors } from '../ducks';
import CycleCreateWarnings from './presenter';
function mapStateToProps(state, props) {
    const { modelSelector, warnings } = props;
    const mode = selectors.getMode(state);
    const model = modelSelector(state);
    return { model, mode, warnings };
}
export default connect(mapStateToProps)(CycleCreateWarnings);
