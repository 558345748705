import { find, keyBy, map, orderBy } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { getCurrentUser } from '../../../services/CurrentUser';
import { removeAllAggregatedNotifications } from '../services/aggregation';
export const SLICE_NAME = 'notifications';
const SHOW_NOTIFICATIONS_DROPDOWN = `${SLICE_NAME}/SHOW_NOTIFICATIONS_DROPDOWN`;
const INIT_COUNT = `${SLICE_NAME}/INIT_COUNT`;
const UPDATE_COUNT = `${SLICE_NAME}/DO_UPDATE_COUNT`;
const MARK_AS_READ = `${SLICE_NAME}/MARK_AS_READ`;
const ADD_NOTIFICATIONS = `${SLICE_NAME}/ADD`;
const GET_NOTIFICATIONS = `${SLICE_NAME}/GET`;
const MARK_ALL_AS_READ = `${SLICE_NAME}/MARK_ALL_AS_READ`;
const MARK_AS_UNREAD = `${SLICE_NAME}/MARK_AS_UNREAD`;
export const INITIAL_STATE = {
    showNotificationsDropdown: false,
    countObj: getInitialCountObject(),
    notifications: [],
    loading: false,
};
function getInitialCountObject() {
    const currentUser = getCurrentUser() || {};
    const { initialNotificationsCount } = currentUser;
    return initialNotificationsCount || null;
}
function getSlice(state) {
    return state[SLICE_NAME];
}
function getShowNotificationsDropdown(state) {
    return getSlice(state).showNotificationsDropdown;
}
function getCount(state) {
    const obj = getCountObject(state);
    return obj ? obj.count : 0;
}
function getLastChangeDate(state) {
    const countObj = getCountObject(state);
    return countObj ? countObj.lastCheck : null;
}
function getNotifications(state) {
    return getSlice(state).notifications;
}
function getNotification(state, id) {
    const notifications = getNotifications(state);
    return find(notifications, { id });
}
function getCountObject(state) {
    return getSlice(state).countObj;
}
function getIsLoading(state) {
    return getSlice(state).loading;
}
function doShowNotificationsDropdown(show) {
    return createAction(SHOW_NOTIFICATIONS_DROPDOWN)(show);
}
function doInitCount(countObj) {
    return createAction(INIT_COUNT)(countObj);
}
function doUpdateCount(count) {
    return createAction(UPDATE_COUNT)(count);
}
function doMarkAsRead(id, closeDropdown = false) {
    return createAction(MARK_AS_READ)({ id, closeDropdown });
}
function doAddNotifications(notifications) {
    return createAction(ADD_NOTIFICATIONS)(notifications);
}
function doGetNotifications({ offset = 0, limit = 10, pushAlert = false } = {}) {
    return createAction(GET_NOTIFICATIONS)({ offset, limit, pushAlert });
}
function doMarkAllAsRead() {
    return createAction(MARK_ALL_AS_READ)();
}
function doMarkAsUnread(id) {
    return createAction(MARK_AS_UNREAD)({ id });
}
function applyShowNotificationsDropdown(state, { payload }) {
    return Object.assign(Object.assign({}, state), { showNotificationsDropdown: payload });
}
function applyInitCount(state, { payload }) {
    return Object.assign(Object.assign({}, state), { countObj: payload });
}
function applyUpdateCount(state, { payload }) {
    const obj = state.countObj;
    if (!obj || obj.count === payload) {
        return state;
    }
    return updateCount(state, payload);
}
function applyMarkAsRead(state, { payload }) {
    const { id, closeDropdown } = payload;
    const obj = state.countObj;
    const newState = !obj || obj.count < 1 ? state : updateCount(state, obj.count - 1);
    const notification = find(newState.notifications, { id });
    const nextState = notification
        ? addOrUpdateNotification(newState, Object.assign(Object.assign({}, notification), { visitedAt: Date.now() }), false)
        : newState;
    if (closeDropdown && nextState.showNotificationsDropdown) {
        return Object.assign(Object.assign({}, nextState), { showNotificationsDropdown: false });
    }
    else {
        return nextState;
    }
}
function applyGetNotifications(state) {
    return Object.assign(Object.assign({}, state), { loading: true });
}
function applyAddNotifications(state, { payload }) {
    return addOrUpdateNotifications(state, payload);
}
function addOrUpdateNotification(state, notification) {
    return addOrUpdateNotifications(state, [notification]);
}
function addOrUpdateNotifications(state, newNotifications, checkAggregation = true) {
    const oldNotifications = checkAggregation
        ? removeAllAggregatedNotifications(newNotifications, state.notifications)
        : state.notifications;
    const tree = keyBy(oldNotifications, 'id');
    const updates = keyBy(newNotifications, 'id');
    const newTree = Object.assign(Object.assign({}, tree), updates);
    return Object.assign(Object.assign({}, state), { notifications: orderBy(newTree, 'createdAt', 'desc'), loading: false });
}
function updateCount(state, newCount) {
    const { countObj } = state;
    if (!countObj) {
        return state;
    }
    return Object.assign(Object.assign({}, state), { countObj: Object.assign(Object.assign({}, state.countObj), { count: newCount }) });
}
function applyMarkAllAsRead(state) {
    const now = Date.now();
    const { notifications } = state;
    const nextNotifications = map(notifications, notification => {
        return notification.visitedAt ? notification : Object.assign(Object.assign({}, notification), { visitedAt: now });
    });
    return Object.assign(Object.assign({}, state), { notifications: nextNotifications });
}
function applyMarkAsUnread(state, { payload }) {
    const { id } = payload;
    const notification = find(state.notifications, { id });
    if (notification.visitedAt) {
        return addOrUpdateNotification(state, Object.assign(Object.assign({}, notification), { visitedAt: null }), false);
    }
    else {
        return state;
    }
}
const reducer = handleActions({
    [SHOW_NOTIFICATIONS_DROPDOWN]: applyShowNotificationsDropdown,
    [INIT_COUNT]: applyInitCount,
    [UPDATE_COUNT]: applyUpdateCount,
    [MARK_AS_READ]: applyMarkAsRead,
    [ADD_NOTIFICATIONS]: applyAddNotifications,
    [GET_NOTIFICATIONS]: applyGetNotifications,
    [MARK_ALL_AS_READ]: applyMarkAllAsRead,
    [MARK_AS_UNREAD]: applyMarkAsUnread,
}, INITIAL_STATE);
export const actionTypes = {
    SHOW_NOTIFICATIONS_DROPDOWN,
    INIT_COUNT,
    UPDATE_COUNT,
    MARK_AS_READ,
    ADD_NOTIFICATIONS,
    GET_NOTIFICATIONS,
    MARK_ALL_AS_READ,
    MARK_AS_UNREAD,
};
export const selectors = {
    getShowNotificationsDropdown,
    getCount,
    getCountObject,
    getLastChangeDate,
    getNotifications,
    getNotification,
    getIsLoading,
};
export const actionCreators = {
    doShowNotificationsDropdown,
    doInitCount,
    doUpdateCount,
    doAddNotifications,
    doGetNotifications,
    doMarkAsRead,
    doMarkAllAsRead,
    doMarkAsUnread,
};
export const reducers = {
    [SLICE_NAME]: reducer,
};
