import { jsx as _jsx } from "react/jsx-runtime";
import { take } from 'lodash/fp';
import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import vars from '../../../../style/base/vars.less';
import { pxToNumber } from '../../../common/services/number-formatter';
const addRefForStepContext = createContext(() => { });
const headerHeight = pxToNumber(vars.headerHeight);
const baseOffset = headerHeight + pxToNumber(vars.standardpadding);
export const WizardScroller = ({ currentStepKey, children, }) => {
    const prevStepKeyRef = useRef(null);
    const stepRefs = useRef({});
    const addRefForStep = useCallback((stepKey, ref) => {
        var _a;
        if (ref) {
            stepRefs.current[stepKey] = take(2, [ref, ...((_a = stepRefs.current[stepKey]) !== null && _a !== void 0 ? _a : [])]);
        }
    }, []);
    useEffect(() => {
        var _a, _b;
        const prevStepKey = prevStepKeyRef.current;
        prevStepKeyRef.current = currentStepKey;
        if (!(currentStepKey && prevStepKey)) {
            return;
        }
        const [prevStepRefIncoming, prevStepRefOutgoing] = (_a = stepRefs.current[prevStepKey]) !== null && _a !== void 0 ? _a : [];
        const [currentStepRefIncoming] = (_b = stepRefs.current[currentStepKey]) !== null && _b !== void 0 ? _b : [];
        if (!(prevStepRefIncoming && prevStepRefOutgoing && currentStepRefIncoming)) {
            return;
        }
        const prevStepRectIncoming = prevStepRefIncoming.getBoundingClientRect();
        const prevStepRectOutgoing = prevStepRefOutgoing.getBoundingClientRect();
        const currentStepRectIncoming = currentStepRefIncoming.getBoundingClientRect();
        if (prevStepRectIncoming.top < baseOffset) {
            const offset = prevStepRectIncoming.height - prevStepRectOutgoing.height;
            const scrollBy = currentStepRectIncoming.top - baseOffset + offset;
            window.scrollBy({
                top: scrollBy,
                behavior: 'smooth',
            });
        }
    }, [currentStepKey]);
    return _jsx(addRefForStepContext.Provider, { value: addRefForStep, children: children });
};
const useStepRef = (step) => {
    const addRefForStep = useContext(addRefForStepContext);
    return useCallback((ref) => addRefForStep(step.key, ref), [addRefForStep, step.key]);
};
export const ScrollStep = ({ step, children }) => {
    const stepRef = useStepRef(step);
    return _jsx("div", { ref: stepRef, children: children });
};
