import { forEach, keyBy, map } from 'lodash/fp';
let pendingRequests = [];
const resolveAll = (batchApiFunction, idKey) => () => {
    if (pendingRequests.length) {
        const requests = pendingRequests;
        pendingRequests = [];
        const requestContentBatched = map(r => r.requestContent, requests);
        batchApiFunction(requestContentBatched).then(responses => {
            const responseMap = keyBy(r => r[idKey], responses);
            forEach(r => r.resolve(responseMap[r.requestContent]), requests);
        }, err => forEach(r => r.reject(err), requests));
    }
};
/**
 * Batches the requests for the given api function and resolves them all at once (instead of shooting individual requests).
 * @param {function} batchApiFunction
 * @param {string} requestContent an entity's unique identifier for the request, e.g. id or loginname
 * @param {string} [idKey='id'] This maps the response back to the requestContent.
 * @param {number} [timeout=8]
 * @returns {Promise}
 */
export const batchRequest = (batchApiFunction, requestContent, idKey = 'id', timeout = 8) => {
    return new Promise((resolve, reject) => {
        pendingRequests.push({
            requestContent,
            resolve,
            reject,
        });
        setTimeout(resolveAll(batchApiFunction, idKey), timeout);
    });
};
