// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PageHeader-header-rX-kn {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  margin: -10px;\n}\n@media (max-width: 650px) {\n  .PageHeader-header-rX-kn {\n    flex: 1 1 100%;\n    text-align: center;\n    justify-content: center;\n  }\n}\n.PageHeader-si-content-width-max-m-1epz0 .PageHeader-header-rX-kn {\n  flex: 1 1 100%;\n  text-align: center;\n  justify-content: center;\n}\n.PageHeader-heading-1Vnn3 {\n  color: #4d4d4c;\n  font-weight: 500;\n  margin: 10px;\n  --fi-stroke: var(--fi-stroke-m);\n}\n", ""]);
// Exports
exports.locals = {
	"header": "PageHeader-header-rX-kn",
	"si-content-width-max-m": "PageHeader-si-content-width-max-m-1epz0",
	"heading": "PageHeader-heading-1Vnn3"
};
module.exports = exports;
