import { jsx as _jsx } from "react/jsx-runtime";
import { IconModuleReviews } from 'featherico';
import api from '../../../../../../../api';
const STATUS = {
    OK: 'OK',
    NOT_FOUND: 'NOT_FOUND',
    PRIVATE: 'NOT_ALLOWED_TO_SEE',
};
const CID_RE = /cid\/([^\\s]+)\/id\/([^\\s]+)/;
const AID_RE = /aid\/([^\\s]+)/;
const ID_RE = /id\/([^\\s]+)/;
export const type = 'review';
export const reFragment = 'appraisal(?:View|Details)/';
export const checkId = id => CID_RE.test(id) || AID_RE.test(id) || ID_RE.test(id);
const getParamsFromId = id => {
    const aidMatch = id.match(AID_RE);
    if (aidMatch) {
        return { reviewId: aidMatch[1] };
    }
    const cidMatch = id.match(CID_RE);
    if (cidMatch) {
        return { cycleId: cidMatch[1], userId: cidMatch[2] };
    }
    const idMatch = id.match(ID_RE);
    return { reviewId: idMatch[1] };
};
export const resolve = {
    previewWithStatus: ({ id }) => {
        const params = getParamsFromId(id);
        return api.contentItemPreview.getReviewPreview(params);
    },
};
export const render = props => {
    const { preview, status } = props.previewWithStatus;
    if (status === STATUS.OK) {
        return `${preview.reviewee.name} ↔ ${preview.manager.name}`;
    }
    if (status === STATUS.NOT_FOUND) {
        return 'Non-existant review';
    }
    if (status === STATUS.PRIVATE) {
        return 'Private review';
    }
};
export const isErrorState = props => {
    return props.previewWithStatus && props.previewWithStatus.status !== STATUS.OK;
};
export const getIcon = () => {
    return _jsx(IconModuleReviews, {});
};
