import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as dateHelper from '../../../../common/services/date-helper/service';
import { getCurrentUser } from '../../../services/CurrentUser';
export default function DateDisplayMinimal({ date, tooltipPos, className, style, withTooltip = true }) {
    const companySettings = getCurrentUser().company.companySettings;
    const minimalDate = dateHelper.toMinimalDate(date, companySettings);
    const tooltipDate = dateHelper.descriptiveDate(date, companySettings);
    return (_jsx("span", { "data-tooltip": withTooltip ? tooltipDate : null, "data-tooltip-options": tooltipPos, className: className, style: style, children: minimalDate }));
}
DateDisplayMinimal.propTypes = {
    date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tooltipPos: PropTypes.string,
};
DateDisplayMinimal.defaultProps = {
    tooltipPos: 'oneline',
    className: '',
    style: {},
};
