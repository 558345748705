import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
export default class SafeImage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { src } = this.props;
        return _jsx("img", { src: src, alt: "" });
    }
}
