import { forEach, kebabCase, reduce, toPairs } from 'lodash/fp';
import ReactDOM from 'react-dom';
export function createMountPoint(type, data, children = '') {
    const tag = 'rich-text-mount-point';
    const dataString = reduce((mem, [k, v]) => mem + ` data-${kebabCase(k)}="${v}"`, '', toPairs(data));
    return `<${tag} class="${type}" ${dataString} >${children}</${tag}>`;
}
export function forEachMountPoint(node, type, callback) {
    const mountedPoints = node.getElementsByClassName(type);
    forEach(mountPoint => {
        ReactDOM.render(callback(mountPoint.dataset), mountPoint);
    }, mountedPoints);
}
