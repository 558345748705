import { jsx as _jsx } from "react/jsx-runtime";
import { filter, map } from 'lodash';
import { createElement } from 'react';
import AlertBox from '../../AlertBox';
export default function CycleCreateWarnings({ warnings, model, mode }) {
    const warningsToShow = filter(warnings, warning => {
        return warning.isShown && warning.isShown(model, mode);
    });
    if (!warningsToShow.length) {
        return _jsx("noscript", {});
    }
    return (_jsx("div", { className: "guided-cycle-create-warnings", children: map(warningsToShow, (warning, key) => {
            return (_jsx(AlertBox, { colour: "RED", children: createElement(warning, { model, mode }) }, key));
        }) }));
}
