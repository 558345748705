import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import Button from '../../Button';
import NotificationItem from '../../Notifications/NotificationItem';
import styles from './style.m.less';
export default class NotificationAlert extends Component {
    componentWillUnmount() {
        const { onUnmount } = this.props;
        if (onUnmount) {
            onUnmount();
        }
    }
    render() {
        const { item, remainingCount, onShowMore } = this.props;
        const countMsgToDisplay = remainingCount > 15 ? ' many more ' : `+${remainingCount} other new `;
        return (_jsxs("div", { className: styles.container, children: [_jsx(NotificationItem, { item: item, hideReadUnreadButton: true }), remainingCount ? (_jsx("span", { className: styles.link, children: _jsxs(Button, { kind: "LINK", onClick: onShowMore, "aria-hidden": true, children: [countMsgToDisplay, " notification", remainingCount === 1 ? '' : 's'] }) })) : null] }));
    }
}
