import PropTypes from 'prop-types';
const regexp = /(<a )(.*?href.*?>)/g;
export const name = 'openLinksInExternalTab';
export const propType = PropTypes.bool;
export const defaultProp = true;
export const onParse = content => {
    return content.replace(regexp, (m, g1, g2) => {
        return `${g1}target="_blank" rel="noopener noreferrer" ${g2}`;
    });
};
