import { jsx as _jsx } from "react/jsx-runtime";
import { IconModulePraise } from 'featherico';
import api from '../../../../../../../api';
const STATUS = {
    OK: 'OK',
    NOT_FOUND: 'NOT_FOUND',
    PRIVATE: 'NOT_ALLOWED_TO_SEE',
};
export const type = 'praise';
export const reFragment = 'app/praise/';
export const checkId = id => /[^\\s]+/.test(id);
export const resolve = {
    previewWithStatus: ({ id }) => api.contentItemPreview.getPraisePreview({ id }),
};
export const render = props => {
    const { preview, status } = props.previewWithStatus;
    if (status === STATUS.OK) {
        return preview.title;
    }
    if (status === STATUS.NOT_FOUND) {
        return 'Non-existant praise';
    }
    if (status === STATUS.PRIVATE) {
        return 'Private praise';
    }
};
export const isErrorState = props => {
    return props.previewWithStatus && props.previewWithStatus.status !== STATUS.OK;
};
export const getIcon = () => {
    return _jsx(IconModulePraise, {});
};
