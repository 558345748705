import { find, map, reduce } from 'lodash/fp';
import * as message from './message';
import * as objective from './objective';
import * as praise from './praise';
import * as review from './review';
const ITEMS = [message, praise, review, objective];
const ITEM_BY_TYPE = transformItem(item => ({ [item.type]: item }));
const getTypeByFragment = fragment => (find(item => fragment.match(item.reFragment), ITEMS) || {}).type;
function transformItem(transformer) {
    return reduce((mem, item) => (Object.assign(Object.assign({}, mem), transformer(item))), {}, ITEMS);
}
const RE = new RegExp('https?:.+?(?:small-improvements.com|localhost:8080)/' +
    `(${map(item => item.reFragment, ITEMS).join('|')})` +
    '([^\\s.,!?\\n<]+)(?=[\\s.,!?\\n<])?');
export const match = link => {
    const matchData = RE.exec(link);
    if (!matchData) {
        return null;
    }
    const [m, fragment, id] = matchData; // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
    const type = getTypeByFragment(fragment);
    if (!type) {
        return null;
    }
    const item = ITEM_BY_TYPE[type];
    if (!item.checkId(id)) {
        return null;
    }
    return { type, id };
};
export const resolve = data => {
    const item = ITEM_BY_TYPE[data.type];
    return item.resolve;
};
export const getIcon = data => {
    const item = ITEM_BY_TYPE[data.type];
    return item.getIcon();
};
export const render = props => {
    const item = ITEM_BY_TYPE[props.type];
    return item.render(props);
};
export const isErrorState = props => {
    const item = ITEM_BY_TYPE[props.type];
    const { isErrorState: fn } = item;
    return fn ? fn(props) : false;
};
