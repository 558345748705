import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { createMountPoint, forEachMountPoint } from '../../helper';
import Mention from './Mention';
export const name = 'mentions';
export const propType = PropTypes.bool;
export const defaultProp = true;
const RTE_MENTION = 'rte-mention';
const TRIGGER = '@';
const TRIGGER_REGEX = new RegExp('(^|[\\s>;])' + TRIGGER + '([^\\s<&]+)', 'gm');
export function onParse(content) {
    content = content || ''; // Don't use a default value, as someone might pass null
    const converted = content.replace(TRIGGER_REGEX, (match, ws, reference) => {
        let punctuation = '';
        const punct = reference.match(/(.*?)?(&nbsp;|[,;.!?)\]}])+$/);
        if (punct) {
            reference = punct[1];
            punctuation = punct[2];
        }
        return [ws, createMountPoint(RTE_MENTION, { loginname: reference }), punctuation].join('');
    });
    return converted;
}
export function onMount(node) {
    forEachMountPoint(node, RTE_MENTION, data => _jsx(Mention, { loginname: data.loginname }));
}
