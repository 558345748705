import { compose, floor, identity, multiply, repeat, replace, size } from 'lodash/fp';
// Our markup versions are building on top of each other. If you want to add
// a new version, then add it to the bottom of the list. Please also provide
// a quick explanation for historical context.
// Keep in mind the compatibility problem: Whenever you declare (and release)
// a new markup version, there still might be people sitting on old frontends
// that don’t support them yet. In this case the engine falls back to the latest
// known version. So you should make sure that this doesn’t blow up then on the
// client.
export default [
    // v1 is what all markup defaults to, even if they don’t have a version number specified.
    // (Don’t remove this transformer, even though it’s trivial!)
    { version: 'v1', transform: identity },
    // Quill used <div> tags for paragraphs back then, but now we use proper <p>’s
    {
        version: 'v2',
        transform: compose(replace(/<div/g, '<p'), replace(/<\/div>/g, '</p>')),
    },
    // Remove empty lines, our paragraphs didn’t have vertical margin back then
    // If there are multiple blank lines, try to substitute them
    {
        version: 'v3',
        transform: replace(/(<p><br><\/p>)+/g, compose(n => repeat(n, '<p><br></p>'), floor, multiply(0.5), size, x => x.match(/<p><br><\/p>/g))),
    },
    // Marks new output generated by Quill 2's getSemanticHTML
    // Main difference is that we now get semantic lists, indented ul/ol are properly nested
    // We can consider converting from the old format, but for now we just keep the necessary css styling around
    {
        version: 'v4',
        transform: identity,
    },
];
