import { getCurrentUser } from '../CurrentUser';
import siDefaultWordings from './siDefaultWordings';
let replacements = Object.assign(Object.assign({}, siDefaultWordings), (getCurrentUser() || {}).customReplacements);
export default function replacement(key) {
    var _a, _b;
    return (_b = (_a = replacements[key]) === null || _a === void 0 ? void 0 : _a.replace(/[<>{}]/g, '')) !== null && _b !== void 0 ? _b : key;
}
export function setReplacements(dictionary) {
    replacements = Object.assign(Object.assign({}, replacements), dictionary);
}
