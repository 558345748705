// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationAlert-link-1e0_H {\n  display: block;\n  padding: 5px 10px;\n  text-align: center;\n  font-weight: 400;\n  font-size: 13px;\n  --fi-size: 14px;\n  --fi-stroke: var(--fi-stroke-s);\n}\n", ""]);
// Exports
exports.locals = {
	"link": "NotificationAlert-link-1e0_H"
};
module.exports = exports;
