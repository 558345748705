export default class ReducerInjector {
    constructor(initialReducers = {}) {
        this.reducers = Object.assign({}, initialReducers);
        this.emitChange = null;
    }
    inject(newReducers) {
        this.reducers = Object.assign(Object.assign({}, this.reducers), newReducers);
        if (this.emitChange) {
            this.emitChange(this.reducers);
        }
    }
    getReducers() {
        return this.reducers;
    }
    setChangeListener(listener) {
        if (this.emitChange) {
            // eslint-disable-next-line no-console
            console.log('ReducerInjector: Change listener already present - overriding');
        }
        this.emitChange = listener;
    }
}
