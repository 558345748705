import { curry, drop, findIndex, getOr, last, reduce } from 'lodash/fp';
export const MARKUP_STRUCTURE = /(<!--MARKUP_VERSION:(.+)-->)(.*)/;
const makeDirective = version => `<!--MARKUP_VERSION:${version}-->`;
const tokenize = htmlString => {
    const regexMatch = htmlString.match(MARKUP_STRUCTURE);
    return {
        version: getOr('v1', 2, regexMatch),
        content: getOr(htmlString, 3, regexMatch),
    };
};
const upgrade = (transformers, actualVersion, content) => {
    let indexOfLastAppliedTransformer = findIndex(t => t.version === actualVersion, transformers);
    if (indexOfLastAppliedTransformer === -1) {
        indexOfLastAppliedTransformer = transformers.length - 1;
    }
    const pendingTransformers = drop(indexOfLastAppliedTransformer + 1, transformers);
    return reduce((c, t) => t.transform(c), content, pendingTransformers);
};
export default curry((transformers, htmlString) => {
    const token = tokenize(htmlString);
    const latestVersion = last(transformers).version;
    return {
        version: latestVersion,
        versionDirective: makeDirective(latestVersion),
        initialVersion: token.version,
        content: upgrade(transformers, token.version, token.content),
    };
});
