import { map } from 'lodash/fp';
import { call } from 'redux-saga/effects';
export default class SagaInjector {
    constructor() {
        this.sagaMiddleware = null;
    }
    inject(sagas) {
        function* saga() {
            yield map(call, sagas);
        }
        if (this.sagaMiddleware) {
            this.sagaMiddleware.run(saga);
        }
        else {
            throw new Error('No saga middleware configured');
        }
    }
    setMiddleware(sagaMiddleware) {
        this.sagaMiddleware = sagaMiddleware;
    }
}
