// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CycleCreateContent-focusContainer-3cRh3 {\n  overflow: hidden;\n}\n.CycleCreateContent-stickyButton-urH5E {\n  position: -webkit-sticky;\n  position: sticky;\n  background: rgba(255, 255, 255, 0.8);\n  bottom: 0;\n  padding-bottom: 10px;\n  margin-bottom: -10px;\n}\n.CycleCreateContent-sectionHeader-tvKlp {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #edeceb;\n  gap: 10px;\n  padding-bottom: 10px;\n  margin-bottom: 20px;\n}\n.CycleCreateContent-sectionHeader-tvKlp .card-section-heading {\n  margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"focusContainer": "CycleCreateContent-focusContainer-3cRh3",
	"stickyButton": "CycleCreateContent-stickyButton-urH5E",
	"sectionHeader": "CycleCreateContent-sectionHeader-tvKlp"
};
module.exports = exports;
