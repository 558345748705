import { jsx as _jsx } from "react/jsx-runtime";
import c from 'classnames';
import { useEffect, useState } from 'react';
import useEventBus, { EventType, createEventBusPublisher } from '../../hooks/useEventBus';
import styles from './style.m.less';
export function Shaking({ children, shakeKey, duration = 800, className }) {
    const [isShaking, setIsShaking] = useState(false);
    useEventBus(EventType.Shake, incomingShakeKey => {
        if (incomingShakeKey === shakeKey) {
            setIsShaking(true);
        }
    });
    useEffect(() => {
        const handle = setTimeout(() => setIsShaking(false), duration);
        return () => clearTimeout(handle);
    }, [isShaking, duration]);
    return (_jsx("div", { className: c({
            [className !== null && className !== void 0 ? className : styles.shaker]: isShaking,
        }), children: children }));
}
const publishShakeEvent = createEventBusPublisher(EventType.Shake);
export function useShaker(shakeKey) {
    return () => publishShakeEvent(shakeKey);
}
