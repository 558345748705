export default {
    "wrapperWidth": "1400px",
    "menuWidth": "200px",
    "mainWrapperContentPadding": "40px",
    "standardpadding": "20px",
    "headerFontSize": "15px",
    "headerHeight": "50px",
    "offlineWarningHeight": "38px",
    "offlineWarningHeightMobile": "56px",
    "promptWidth": "560px",
    "clippyTriggerSize": "36px",
    "clippyItemMinWidth": "250px",
    "clippyBackgroundColor": "#f5f4f3",
    "clippyAnimationDuration": "0.5s",
    "layoutPaddingM": "30px",
    "layoutPaddingS": "20px",
    "layoutPaddingXS": "10px",
    "cardSizeXXXS": "80px",
    "cardSizeXXS": "140px",
    "cardSizeXS": "200px",
    "cardSizeS": "260px",
    "cardSizeM": "320px",
    "cardSizeL": "400px",
    "cardSizeXL": "500px",
    "cardSizeXXL": "650px",
    "boxSize": "40px",
    "boxSizeSmall": "28px",
    "boxSizeExtraSmall": "22px",
    "avatarSizeXXS": "16px",
    "avatarSizeXS": "20px",
    "avatarSizeS": "25px",
    "avatarSizeM": "30px",
    "avatarSizeL": "40px",
    "avatarSizeXL": "50px",
    "avatarSizeXXL": "75px",
    "avatarSizeFull": "400px",
    "userImageSizeL": "48px",
    "iconBadgeSizeS": "14px",
    "iconBadgeSizeL": "20px",
    "objectiveIconSize": "40px",
    "objectiveIconSizeSmall": "24px",
    "fontSizeXS": "11px",
    "fontSizeS": "13px",
    "fontSize": "15px",
    "fontSizeL": "18px",
    "fontSizeXL": "22px",
    "fontSizeXXL": "26px",
    "fontSizeJumbo": "60px",
    "defaultLineHeight": "21px",
    "relativeLineHeight": "1.6",
    "titleLineHeight": "1.25",
    "buttonLineHeight": "18px",
    "defaultFont": "'TTNormsPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    "defaultFontVariant": "tabular-nums",
    "fontWeight": "400",
    "fontWeightMedium": "500",
    "fontWeightBold": "600",
    "zIndexClippy": "50",
    "zIndexMainNavigation": "100",
    "zIndexFullPageLoadingState": "99",
    "zIndexStickyFloater": "300",
    "zIndexPopover": "599",
    "zIndexHeader": "600",
    "zIndexOfflineWarning": "610",
    "zIndexPopupAlert": "550",
    "zIndexTooltip": "700",
    "zIndexDropdown": "700",
    "zIndexOverlay": "900",
    "zIndexAboveIntercom": "2147483005",
    "zIndexRteToolbar": "1",
    "zIndexRteCalloutShadow": "0",
    "zIndexRteCallout": "400",
    "zIndexRteTooltip": "401",
    "zIndexSectionEditOverlay": "500",
    "zIndexSectionEditOverlayToggles": "550",
    "zIndexZenMode": "601",
    "zIndexZenModeStickyElements": "2",
    "testDriveModeHeight": "160px",
    "tui_defaultspacing": "6px",
    "calloutArrowSize": "5px",
    "blurFilter": "blur(1px)",
    "borderRadius": "4px",
    "borderColor": "#e0dfde",
    "mainBorder": "#c7c6c5",
    "borderGrey": "#e0dfde",
    "borderColorShadowLight": "rgba(0, 0, 0, 0.07)",
    "borderColorNone": "rgba(0, 0, 0, 0)",
    "borderColorShadowDark": "rgba(0, 0, 0, 0.12)",
    "borderColorTransition": "border-color 0.2s ease-in-out",
    "border": "1px solid #e0dfde",
    "lightDashedBorder": "#e0dfde 1px dashed",
    "commentBorder": "1px solid rgba(0, 0, 0, 0.08)",
    "textFieldBorderColor": "#c7c6c5",
    "focusBorderColor": "#0071d4",
    "focusBorderColorOnBlue": "#0f4e96",
    "singleFocusBoxShadow": "0 0 0 1px #0071d4",
    "singleFocusBoxShadowOnBlue": "0 0 0 1px #0f4e96",
    "doubleFocusBoxShadow": "0 0 0 2px #0071d4",
    "cardBackgroundColor": "#ffffff",
    "cardBorderColor": "#e0dfde",
    "cardSeparatorGrey": "#edeceb",
    "cardPadding": "20px",
    "cardMargin": "20px",
    "cardBorder": "1px solid",
    "cardBorderRadius": "4px",
    "cardBorderWidth": "1px",
    "tabPadding": "0 10px 10px",
    "boxBackgroundColor": "#f5f4f3",
    "highlightColor": "#ffff00",
    "overlayBackgroundColor": "rgba(50, 50, 50, 0.8)",
    "menuFontColor": "#4d4d4c",
    "menuHover": "#0071d4",
    "menuBackground": "#ffffff",
    "footerFontColor": "#a3a2a1",
    "bodyBackgroundColor": "#faf9f7",
    "bodyBackgroundColorMobile": "#f5f4f3",
    "contentBackground": "#ffffff",
    "contentFontColor": "#1a1a1a",
    "linkFontColor": "#0071d4",
    "linkFontColorInverted": "#ffffff",
    "subduedTextOnGrey": "#7a7a79",
    "subduedTextOnWhite": "#a3a2a1",
    "warningColor": "#ff6464",
    "warningForegroundColor": "#806600",
    "warningRedForegroundColor": "#f23037",
    "warningRedBackgroundColor": "#fff3f3",
    "warningYellowForegroundColor": "#ffcc00",
    "warningYellowBackgroundColor": "#fff8d9",
    "warningGreenForegroundColor": "#2ecd72",
    "warningGreenBackgroundColor": "#eafaf0",
    "warningBlueForegroundColor": "#0071d4",
    "warningBlueBackgroundColor": "#e6f3ff",
    "warningNeutralBackgroundColor": "#faf9f7",
    "warningNeutralForegroundColor": "#a3a2a1",
    "headingFontColor": "#4d4d4c",
    "optionHeaderFontColor": "#a3a2a1",
    "headerBackgroundColor": "rgba(255, 255, 255, 0.95)",
    "headerMenuHover": "#0071d4",
    "headerMenuActive": "#1362a8",
    "headerMenuHoverFontColor": "#ffffff",
    "dropdownSidePadding": "5px",
    "dropdownItemBackgroundColor": "#ffffff",
    "dropdownItemHoverBackgroundColor": "#edeceb",
    "dropdownItemActiveBackgroundColor": "#0071d4",
    "dropdownItemActiveTextColor": "#ffffff",
    "dropdownBorder": "1px solid #e0dfde",
    "dropdownShadow": "0 1px 4px rgba(0, 0, 0, 0.1)",
    "dropdownShadowClippy": "0 0 16px rgba(27, 39, 51, 0.15)",
    "dropdownShadowClippyModal": "0 4px 16px 0 rgba(0, 0, 0, 0.15)",
    "dropdownShadowDatepicker": "0 1px 4px 0 rgba(0, 0, 0, 0.1)",
    "dropdownBorderRadius": "4px",
    "draggingBoxShadow": "0 0 16px 0 rgba(0, 0, 0, 0.15)",
    "selectGap": "5px",
    "buttonColor": "#ffffff",
    "buttonColorSave": "#ffd633",
    "buttonColorDone": "#0071d4",
    "buttonColorDoneHover": "#007fee",
    "buttonColorContinue": "#0071d4",
    "buttonColorWarning": "#f23037",
    "buttonColorSuccess": "#2ecd72",
    "buttonColorLightText": "#ffffff",
    "buttonHoverAndFocusColor": "#ffffff",
    "buttonBorderDefault": "#a3a2a1",
    "buttonFontSize": "15px",
    "buttonHeight": "40px",
    "buttonPadding": "10px",
    "buttonSmallHeight": "28px",
    "buttonSmallPaddingHorizontal": "8px",
    "buttonSmallPaddingVertical": "5px",
    "buttonSmallPadding": "5px 8px",
    "buttonExtraSmallHeight": "22px",
    "buttonExtraSmallPadding": "3px",
    "tableListItemPaddingHorizontal": "5px",
    "tableListItemPadding": "6px 5px",
    "tableListItemHover": "#e6f3ff",
    "tableListItemSelected": "#cce7ff",
    "tableListItemSelectedHover": "#99cfff",
    "checkboxCheckedColor": "#4d4d4c",
    "checkboxHoverColor": "#7a7a79",
    "checkboxUncheckedColor": "#c7c6c5",
    "checkableItemSizeS": "18px",
    "checkableItemSizeM": "25px",
    "datePickerBorderColor": "#e0dfde",
    "datePickerPadding": "5px",
    "datePickerDayName": "#c7c6c5",
    "datePickerDayNameBorder": "#edeceb",
    "datePickerDaySize": "26px",
    "datePickerDayDistance": "2.5px",
    "datePickerHeaderBackground": "#ffffff",
    "datePickerItemHoverBackground": "#cce7ff",
    "datePickerTodayItemBackground": "#edeceb",
    "datePickerCurrentItemText": "#ffffff",
    "datePickerOutsideItemText": "#c7c6c5",
    "datePickerCurrentItemBackground": "#66b6ff",
    "datePickerCurrentItemHoverBackground": "#66b6ff",
    "datePickerHighlightBubbleSize": "10px",
    "datePickerHighlightBubbleColor": "#e0dfde",
    "inputFieldBackgroundColor": "#ffffff",
    "inputFieldBackgroundColorActive": "#ffffff",
    "inputFieldBackgroundColorDisabled": "#f5f4f3",
    "inputFieldBackgroundColorInvalid": "#fff3f3",
    "inputFieldBorder": "1px solid",
    "inputFieldBorderDefaultColor": "#e0dfde",
    "inputFieldBorderHoverColor": "#0071d4",
    "inputFieldBorderActiveColor": "#0071d4",
    "inputFieldBorderInvalidColor": "#f23037",
    "inputFieldBorderDisabledColor": "#e0dfde",
    "inputFieldFocusBoxShadowWidths": "0 0 0 1px",
    "inputFieldDisabledColor": "#7a7a79",
    "inputFieldFontSize": "15px",
    "customInputWidth": "1.5em",
    "inputFieldDefaultWidth": "230px",
    "inputFieldFullWidth": "460px",
    "inputFieldHeight": "40px",
    "inputFieldVerticalSpacing": "30px",
    "inputFieldHorizontalSpacing": "20px",
    "inputFieldPadding": "9.5px",
    "inputFieldSidePadding": "4px",
    "inputFieldSmallPadding": "3px 4px",
    "inputFieldSmallHeight": "28px",
    "inputFieldSmallHeightPadding": "2px",
    "inputFieldSmallHeightInner": "22px",
    "inputFieldSmallFontSize": "13px",
    "inputFieldIconPadding": "11px",
    "inputFieldButtonPadding": "6px",
    "inputFieldButtonPaddingSmall": "3px",
    "inputFieldButtonColor": "#7a7a79",
    "inputFieldButtonHoverColor": "#1a1a1a",
    "inputFieldButtonHoverBackgroundColor": "#edeceb",
    "inputFieldButtonEnabledColor": "#ffffff",
    "inputFieldButtonEnabledBackgroundColor": "#0071d4",
    "rteToolbarBackgroundColor": "#faf9f7",
    "rteContentLineHeight": "1.6",
    "rteContentParagraphSpacing": "10px",
    "loadingBarBackground": "#f5f4f3",
    "loadingBarSlidingBackground": "#e0dfde",
    "modalCloseButtonSize": "18px",
    "modalCloseButtonColor": "#7a7a79",
    "modalCloseButtonColorHover": "#4d4d4c",
    "modalPadding": "20px",
    "modalPaddingS": "20px",
    "modalPaddingXS": "10px",
    "tooltipArrow": "6px",
    "tooltipBackground": "#4d4d4c",
    "tooltipFontColor": "#ffffff",
    "tooltipWidth": "200px",
    "tooltipFontSize": "13px",
    "tooltipLineHeight": "18px",
    "tooltipPadding": "10px",
    "rteOrderedListIndent": "1.8em",
    "rteUnorderedListIndent": "1.8em",
    "rteParagraphIndent": "1.8em",
    "reviewStatusContributor": "#e6f3ff",
    "reviewStatusNotStarted": "#f23037",
    "reviewStatusStarted": "#ffd633",
    "reviewStatusWritten": "#fcac53",
    "reviewStatusShared": "#aeef88",
    "reviewStatusSigned": "#2ecd72",
    "surveyQuestionEditorWidth": "720px",
    "surveyFormWidth": "600px",
    "surveyAnswerCheckboxWidth": "75px",
    "surveyAnswersFullWidth": "1200px",
    "zenModeMaxContainerWidth": "940px",
    "zenModeMaxFormWidth": "660px",
    "notificationItemBorderStyle": "1px solid #e0dfde",
    "noteItemBackgroundColor": "#faf9f7",
    "noteItemBorder": "1px solid #e0dfde",
    "feedbackQuestionMarginBottom": "10px",
    "noAuthContentMaxHeight": "800px",
    "noAuthHeaderLogoHeight": "40px",
    "noAuthHeaderHeight": "100px"
}
