import { __rest } from "tslib";
import { createElement } from 'react';
import { getCurrentUser } from '../../services/CurrentUser';
import { attachProfileInfo } from '../../services/ProfileInfo';
function PrepareUser(component) {
    return props => {
        const { user, useFirstName } = props, otherProps = __rest(props, ["user", "useFirstName"]);
        const realUser = attachProfileInfo(getCurrentUser(), user, useFirstName);
        const passedProps = Object.assign({ user: realUser, useFirstName }, otherProps);
        return createElement(component, passedProps);
    };
}
export default PrepareUser;
