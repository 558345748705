// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RichLink-content-1E6nf {\n  background: #ffffff;\n  border: 1px solid #e0dfde;\n  border-radius: 4px;\n  cursor: pointer;\n  display: inline-block;\n  vertical-align: -0.8em;\n  max-width: 220px;\n  overflow: hidden;\n  padding: 4px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.RichLink-error-C1xZi {\n  color: gray;\n}\n", ""]);
// Exports
exports.locals = {
	"content": "RichLink-content-1E6nf",
	"error": "RichLink-error-C1xZi"
};
module.exports = exports;
