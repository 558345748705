import { jsx as _jsx } from "react/jsx-runtime";
import { IconFileExcel, IconFilePdf, IconFileWord } from 'featherico';
import { keyBy } from 'lodash/fp';
import * as uriHelper from '../../helpers/uri';
const MATCHERS = [
    {
        type: 'pdf',
        re: /\.pdf$/,
        toText: uriHelper.extractFileName,
        icon: IconFilePdf,
    },
    {
        type: 'excel',
        re: /\.xlsx?$/,
        toText: uriHelper.extractFileName,
        icon: IconFileExcel,
    },
    {
        type: 'word',
        re: /\.docx?$/,
        toText: uriHelper.extractFileName,
        icon: IconFileWord,
    },
];
const MATCHER_BY_TYPE = keyBy('type', MATCHERS);
export function match(link) {
    for (let i = 0; i < MATCHERS.length; i++) {
        const matcher = MATCHERS[i];
        if (matcher.re.test(link)) {
            return {
                type: matcher.type,
                text: matcher.toText(link),
            };
        }
    }
    return null;
}
export function getIcon({ type }) {
    const { icon: Icon } = MATCHER_BY_TYPE[type];
    return _jsx(Icon, {});
}
export function render(props) {
    return props.text || props.link;
}
