import * as DOMPurify from 'dompurify';
// DOMPurify does not have an option to allow only specific classes,
// so we implement it ourselves by hooking into the sanitizer.
DOMPurify.addHook('uponSanitizeElement', function (currentNode, data, config) {
    if (!currentNode.classList) {
        return currentNode;
    }
    const allowedClasses = config.allowedClasses.get(data.tagName);
    if (allowedClasses) {
        for (const clazz of currentNode.classList) {
            if (!allowedClasses.has(clazz)) {
                currentNode.classList.remove(clazz);
            }
        }
        if (currentNode.attributes.class && currentNode.classList.length === 0) {
            currentNode.attributes.removeNamedItem('class');
        }
    }
    else {
        if (currentNode.attributes.class) {
            currentNode.attributes.removeNamedItem('class');
        }
    }
    return currentNode;
});
/** @type {import('dompurify').Config} */
const config = {
    ALLOW_ARIA_ATTR: false,
    // Taken from https://github.com/slab/quill/tree/main/packages/quill/src/formats
    ALLOWED_TAGS: [
        'div',
        'span',
        'p',
        'br',
        'blockquote',
        'b',
        'strong',
        'code',
        'pre',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'img',
        'em',
        'i',
        'a',
        'ol',
        'ul',
        'li',
        'sub',
        'sup',
        's',
        'strike',
        'table',
        'tr',
        'td',
        'tbody',
        'u',
        'rich-text-mount-point',
        'autocomplete-blot',
    ],
    ADD_ATTR: ['contenteditable', 'target'],
    allowedClasses: new Map([
        [
            'li',
            new Set([
                'ql-indent-1',
                'ql-indent-2',
                'ql-indent-3',
                'ql-indent-4',
                'ql-indent-5',
                'ql-indent-6',
                'ql-indent-7',
                'ql-indent-8',
                'ql-indent-9',
            ]),
        ],
        [
            'p',
            new Set([
                'ql-indent-1',
                'ql-indent-2',
                'ql-indent-3',
                'ql-indent-4',
                'ql-indent-5',
                'ql-indent-6',
                'ql-indent-7',
                'ql-indent-8',
                'ql-indent-9',
            ]),
        ],
        ['rich-text-mount-point', new Set(['rte-rich-link', 'rte-mention', 'rte-image'])],
        ['autocomplete-blot', new Set(['rte-replacement', 'rte-custom-replacement'])],
    ]),
};
export function sanitize(str) {
    if (!str) {
        return '';
    }
    return (DOMPurify.sanitize(str, config)
        .replace(/<br \/>/g, '<br>')
        // eslint-disable-next-line no-irregular-whitespace
        .replace(/ /g, '&nbsp;'));
}
