import { jsx as _jsx } from "react/jsx-runtime";
import * as genderService from '../../../../../react/services/Gender';
import * as R from '../../../../../routes';
import * as helper from '../../../../features/ActivityStream/services/templateService/helper';
import { getEditUrl, getViewUrl } from '../../../../features/Reviews/services/url';
import { getReviewerAssessmentWording, getReviewersWording } from '../../../../features/Reviews/services/wording';
import { goToUrl, openInNewTab } from '../../../../services/Router';
import { interpolateAppUrl, prependAppPath } from '../../../../services/Url';
const reviewerWording = getReviewersWording(false);
const reviewerAssessmentWording = getReviewerAssessmentWording(false);
const reviewerAssessmentWordingPlural = getReviewerAssessmentWording(true);
function goToMessageCta(item) {
    return () => goToUrl(interpolateAppUrl(R.MESSAGE_SINGLE, { id: item.entityId }));
}
function goToPraiseCta(item) {
    return () => goToUrl(`/app/praise/${item.entityId}`);
}
function goToYourProfileCta(item) {
    return () => goToUrl(interpolateAppUrl(R.USER_PROFILE, { id: item.entityId }));
}
function goToProfileCta(item) {
    return () => goToUrl(interpolateAppUrl(R.USER_PROFILE, { id: item.actor.id }));
}
function goToObjectiveCta(item) {
    return () => goToUrl(interpolateAppUrl(R.OBJECTIVE_SINGLE_BY_ID, { id: item.entityId }));
}
function goToMeeting(item) {
    return () => goToUrl(interpolateAppUrl(R.MEETING_DEDICATED, { meetingId: item.entityId }));
}
function goToFeedbackProvide(item) {
    return () => goToUrl(interpolateAppUrl(R.FEEDBACK_PROVIDE, { id: item.entityId }));
}
function goToFeedback(item) {
    return () => goToUrl(interpolateAppUrl(R.FEEDBACK_USER_VIEW, { id: item.entityId }));
}
function goToFeedbackTodoList() {
    return () => goToUrl(prependAppPath(R.FEEDBACK_USER) + '?tab=todo');
}
function goToAnytimeFeedbackProvide(item) {
    return () => goToUrl(`/app/anytime-feedback/provide/${item.entityId}/${item.userId}`);
}
function goToAnytimeFeedback(item) {
    return () => goToUrl(`/app/feedback/view/${item.entityId}`);
}
function goToReviewView(item) {
    return () => goToUrl(getViewUrl(item.entityId));
}
function goToReviewEdit(item) {
    return () => goToUrl(getEditUrl(item.entityId));
}
function goToReviewTeamOverview(item) {
    return () => goToUrl(interpolateAppUrl(R.YOUR_TEAM_REVIEWS_WITH_CYCLE, { managerId: item.userId, cycleId: item.entityId }));
}
function goToPulseSurvey(item) {
    return () => goToUrl(interpolateAppUrl(R.YOUR_SURVEYS_WITH_SURVEY, { surveyInstanceId: item.entityId }));
}
function getActor(item) {
    return {
        actor: helper.transformActorName(item.actor),
    };
}
function getDefaultValues(item) {
    return {
        actor: helper.transformActorName(item.actor),
        otherActor: item.otherActor ? helper.transformActorName(item.otherActor) : null,
        additionalActorsCount: item.additionalActorsCount,
        repetition: getRepetitionPhrase(item.repetitionCount),
        possPron: genderService.asPossessivePronoun(item.actor.gender),
    };
}
function getRepetitionPhrase(repetitionCount) {
    if (repetitionCount === 0) {
        return '';
    }
    if (repetitionCount === 1) {
        return 'twice ';
    }
    return `${repetitionCount} times `;
}
function getDefaultActorPhrase(item) {
    if (item.otherActor) {
        return '{{actor}} and {{otherActor}}';
    }
    if (item.additionalActorsCount) {
        return '{{actor}} and {{additionalActorsCount}} others';
    }
    if (!item.aggregateOverActors) {
        return '{{actor}}';
    }
}
function createMessageTemplate(fragment) {
    return {
        chooseTemplate: item => ({
            text: `${getDefaultActorPhrase(item)} ${fragment}`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToMessageCta,
        }),
    };
}
function createPraiseTemplate(fragment) {
    return {
        chooseTemplate: item => ({
            text: `${getDefaultActorPhrase(item)} ${fragment}`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToPraiseCta,
        }),
    };
}
export const TEMPLATES = {
    FEATURE_ANNOUNCEMENT: {
        standard: {
            text: '{{title}}{{text}}',
            getValuesFrom: item => ({
                title: _jsx("strong", { children: item.data.title }),
                text: _jsx("div", { children: item.data.text }),
            }),
            getCallToAction: item => () => openInNewTab(item.data.href),
        },
    },
    FEEDBACK_REQUESTED: {
        standard: {
            text: '{{actor}} requested feedback from you.',
            getValuesFrom: function (item) {
                return {
                    actor: helper.transformActorName(item.actor),
                };
            },
            getCallToAction: goToFeedbackProvide,
        },
    },
    FEEDBACK_NUDGED_TO_WRITE_IN_CYCLE: {
        chooseTemplate: () => ({
            text: `{{actor}} nudged you {{repetition}}to finish your feedback.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToFeedbackTodoList,
        }),
    },
    FEEDBACK_NUDGED_TO_WRITE_SPECIFIC_FEEDBACK: {
        chooseTemplate: () => ({
            text: `{{actor}} nudged you {{repetitionCount}}to finish someone's feedback.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToFeedbackProvide,
        }),
    },
    FEEDBACK_COMPLETED: {
        chooseTemplate: item => ({
            text: `${getDefaultActorPhrase(item)} shared feedback with you!`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToFeedback,
        }),
    },
    ANYTIME_FEEDBACK_REQUESTED: {
        standard: {
            text: '{{actor}} wants your feedback',
            getValuesFrom: function (item) {
                return {
                    actor: helper.transformActorName(item.actor),
                };
            },
            getCallToAction: goToAnytimeFeedbackProvide,
        },
    },
    ANYTIME_FEEDBACK_SHARED_WITH_YOU: {
        standard: {
            text: '{{actor}} shared their feedback with you',
            getValuesFrom: getDefaultValues,
            getCallToAction: goToAnytimeFeedback,
        },
    },
    ANYTIME_FEEDBACK_SHARED_FOR_DIRECT_REPORTS: {
        chooseTemplate: item => ({
            text: `${getDefaultActorPhrase(item)} shared feedback about your direct report.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToAnytimeFeedback,
        }),
    },
    ANYTIME_FEEDBACK_REQUESTED_ABOUT_YOU: {
        standard: {
            text: '{{actor}} asked for feedback about you',
            getValuesFrom: function (item) {
                return {
                    actor: helper.transformActorName(item.actor),
                };
            },
            getCallToAction: goToFeedback,
        },
    },
    MESSAGE_RECEIVED: createMessageTemplate('wrote a :messages.singular: about you.'),
    MESSAGE_OF_AUTHOR_LIKED: createMessageTemplate('liked a :messages.singular: you wrote.'),
    MESSAGE_FOR_RECIPIENT_LIKED: createMessageTemplate('liked a :messages.singular: about you.'),
    MESSAGE_COMMENT_FOR_AUTHOR: createMessageTemplate('commented on a :messages.singular: you wrote.'),
    MESSAGE_COMMENT_FOR_RECIPIENT: createMessageTemplate('commented on a :messages.singular: about you.'),
    MESSAGE_COMMENT_MENTIONED_YOU: createMessageTemplate('mentioned you in a comment on a :messages.singular:.'),
    MESSAGE_MENTIONED_YOU: createMessageTemplate('mentioned you in a :messages.singular:.'),
    PRAISE_RECEIVED: createPraiseTemplate('gave you :praise.singular:.'),
    PRAISE_OF_AUTHOR_LIKED: createPraiseTemplate('liked a :praise.singular: you wrote.'),
    PRAISE_FOR_RECIPIENT_LIKED: createPraiseTemplate('liked a :praise.singular: for you.'),
    PRAISE_COMMENT_FOR_AUTHOR: createPraiseTemplate('commented on a :praise.singular: you wrote.'),
    PRAISE_COMMENT_FOR_RECIPIENT: createPraiseTemplate('commented on a :praise.singular: for you.'),
    PRAISE_COMMENT_MENTIONED_YOU: createPraiseTemplate('mentioned you in a comment on a :praise.singular:.'),
    PRAISE_MENTIONED_YOU: createPraiseTemplate('mentioned you in a :praise.singular:.'),
    MEETING_SHARED: {
        chooseTemplate: () => ({
            text: `{{actor}} has invited you to a new :meeting.name:.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToMeeting,
        }),
    },
    MEETING_CONTENT_UPDATED: {
        chooseTemplate: () => ({
            text: `{{actor}} has made changes to your :meeting.name:.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToMeeting,
        }),
    },
    MEETING_LINKED_TO_CALENDAR: {
        chooseTemplate: () => ({
            text: `A :meeting.name: was created from your calendar.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToMeeting,
        }),
    },
    OBJECTIVE_CREATED_FOR_YOU: {
        standard: {
            text: '{{actor}} created a :objective.singular.big: for you.',
            getValuesFrom: function (item) {
                return {
                    actor: helper.transformActorName(item.actor),
                };
            },
            getCallToAction: goToObjectiveCta,
        },
    },
    OBJECTIVE_COMMENT_CREATED_FOR_OWNER: {
        standard: {
            text: '{{actor}} commented on your :objective.singular.big:.',
            getValuesFrom: function (item) {
                return {
                    actor: helper.transformActorName(item.actor),
                };
            },
            getCallToAction: goToObjectiveCta,
        },
    },
    REVIEW_NUDGE_TO_WORK_ON_SELF_ASSESSMENT: {
        chooseTemplate: () => ({
            text: `{{actor}} nudged you {{repetition}}to finish your :pr.selfAssessment:.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewEdit,
        }),
    },
    REVIEW_NUDGE_TO_WORK_ON_MANAGER_ASSESSMENTS: {
        chooseTemplate: () => ({
            text: `{{actor}} nudged you {{repetition}}to finish your ${reviewerAssessmentWordingPlural}.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewTeamOverview,
        }),
    },
    REVIEW_NUDGE_TO_WORK_ON_SPECIFIC_MANAGER_ASSESSMENT: {
        chooseTemplate: () => ({
            text: `{{actor}} nudged you {{repetition}}to finish a ${reviewerAssessmentWording}.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewEdit,
        }),
    },
    REVIEW_SHARED_WITH_MANAGER: {
        chooseTemplate: () => ({
            text: `{{actor}} shared a :pr.selfAssessment: with you.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewView,
        }),
    },
    // Deprecated only in here to stay compatible
    REVIEW_SHARED_WITH_MANAGER_BY_OTHER_PERSON_THAN_EMPLOYEE: {
        chooseTemplate: () => ({
            text: `{{actor}} shared someone's :pr.selfAssessment: with you.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewView,
        }),
    },
    REVIEW_SHARED_WITH_EMPLOYEE: {
        chooseTemplate: () => ({
            text: `{{actor}} shared a ${reviewerAssessmentWording} with you.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewView,
        }),
    },
    // Deprecated only in here to stay compatible
    REVIEW_SHARED_WITH_EMPLOYEE_BY_OTHER_PERSON_THAN_MANAGER: {
        chooseTemplate: () => ({
            text: `{{actor}} shared your ${reviewerWording}'s ${reviewerAssessmentWording} with you.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewView,
        }),
    },
    REVIEW_SIGNED_BY_EMPLOYEE: {
        chooseTemplate: () => ({
            text: `{{actor}} signed {{possPron}} :pr.singular:.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewView,
        }),
    },
    REVIEW_SIGNED_BY_MANAGER: {
        chooseTemplate: () => ({
            text: `{{actor}} signed your :pr.singular:.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToReviewView,
        }),
    },
    PULSE_SURVEY_AVAILABLE: {
        chooseTemplate: () => ({
            text: `{{actor}} shared a new :surveys.singular: with you.`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToPulseSurvey,
        }),
    },
    ROLE_HR_GRANTED: {
        standard: {
            text: '{{actor}} granted you the permission "HR Admin".',
            getValuesFrom: getActor,
        },
    },
    ROLE_HR_REVOKED: {
        standard: {
            text: '{{actor}} revoked your permission "HR Admin".',
            getValuesFrom: getActor,
            getCallToAction: goToYourProfileCta,
        },
    },
    ROLE_ADMIN_GRANTED: {
        standard: {
            text: '{{actor}} granted you the permission "Tech Admin".',
            getValuesFrom: getActor,
            getCallToAction: goToYourProfileCta,
        },
    },
    ROLE_ADMIN_REVOKED: {
        standard: {
            text: '{{actor}} revoked your permission "Tech Admin".',
            getValuesFrom: getActor,
            getCallToAction: goToYourProfileCta,
        },
    },
    ROLE_ASSISTANT_GRANTED: {
        standard: {
            text: '{{actor}} granted you the permission "HR Assistant".',
            getValuesFrom: getActor,
        },
    },
    ROLE_ASSISTANT_REVOKED: {
        standard: {
            text: '{{actor}} revoked your permission "HR Assistant".',
            getValuesFrom: getActor,
            getCallToAction: goToYourProfileCta,
        },
    },
    REPORT_ASSIGNED_TO_MANAGER: {
        chooseTemplate: () => ({
            text: `You have a new report: {{actor}}`,
            getValuesFrom: getDefaultValues,
            getCallToAction: goToProfileCta,
        }),
    },
};
