import { jsx as _jsx } from "react/jsx-runtime";
import { IconBadgeFail, IconBadgeSuccess } from 'featherico';
import PropTypes from 'prop-types';
function IconValidation({ isValid }) {
    return isValid ? _jsx(IconBadgeSuccess, {}) : _jsx(IconBadgeFail, {});
}
IconValidation.propTypes = {
    isValid: PropTypes.bool,
};
export default IconValidation;
