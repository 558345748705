// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ReadUnreadToggler-unread-2fhsq {\n  fill: currentColor;\n}\n", ""]);
// Exports
exports.locals = {
	"unread": "ReadUnreadToggler-unread-2fhsq"
};
module.exports = exports;
