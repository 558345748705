import { contains, init, isEqual, last } from 'lodash/fp';
export const ANY_VALUE = '_ANY_VALUE';
export const OTHERWISE = '_OTHERWISE';
const ONE_OF = '_ONE_OF';
export const oneOf = list => ({ ifsType: ONE_OF, list });
const matchesPattern = (expected, actual) => {
    if (expected === ANY_VALUE) {
        return true;
    }
    if (expected.ifsType === ONE_OF) {
        return contains(actual, expected.list);
    }
    return isEqual(actual, expected);
};
const caseMatchesArgs = args => currentCase => {
    const matchers = init(currentCase);
    return matchers.every((matcher, i) => matchesPattern(matcher, args[i]));
};
function getFirstMatchingCase(cases, args) {
    const matchingCase = cases.find(caseMatchesArgs(args));
    if (matchingCase) {
        return matchingCase;
    }
    const fallback = last(cases);
    if (fallback[0] === OTHERWISE) {
        return fallback;
    }
}
export const ifs = (...cases) => (...args) => {
    const matchingCase = getFirstMatchingCase(cases, args);
    if (!matchingCase) {
        throw new Error('Non-exhaustive pattern');
    }
    const resultbuilder = last(matchingCase);
    if (matchingCase[0] === OTHERWISE) {
        return resultbuilder(...args);
    }
    else {
        const numMatchersInCase = matchingCase.length - 1;
        const argsForResultBuilder = args.slice(numMatchersInCase);
        return resultbuilder(...argsForResultBuilder);
    }
};
